import { useMemo } from 'react';
import { useMobileDocumentsData } from '../../hooks/use-mobile-documents-data';

export const useMobileData = () => {
  const newData = useMobileDocumentsData();
  return useMemo(() => newData, []);
};

export const useMobileColumns = () =>
  useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Revised',
        accessor: 'revised'
      }
    ],
    []
  );
