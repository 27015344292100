import { useMemo } from 'react';
import { useDocumentsData } from '../../hooks/use-documents-data';
export const useData = () => {
  const newData = useDocumentsData();
  return useMemo(() => newData, []);
};

export const useColumns = () =>
  useMemo(
    () => [
      {
        Header: 'Ref',
        accessor: 'ref' // accessor is the "key" in the data
      },
      {
        Header: 'Name',
        accessor: 'name'
      },
      {
        Header: 'Type',
        accessor: 'type'
      },
      {
        Header: 'Status',
        accessor: 'status'
      },
      {
        Header: 'Department',
        accessor: 'dept'
      },
      {
        Header: 'Created',
        accessor: 'created'
      },
      {
        Header: 'Revised',
        accessor: 'revised'
      },
      {
        Header: 'By',
        accessor: 'by'
      },
      {
        Header: '',
        accessor: 'download'
      }
    ],
    []
  );
