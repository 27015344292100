import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';
import { ReactComponent as Download } from '../components/document-table/assets/download.svg';
import styles from '../components/document-table/document-table.module.scss';

import Button from '../components/button';

export const useMobileDocumentsData = () => {
  const { allMediaDocument } = useStaticQuery(
    graphql`
      query MobileDocumentsData {
        allMediaDocument {
          nodes {
            name
            field_status
            field_reference {
              value
            }
            created(formatString: "M/DD/YY")
            id
            revision_created(formatString: "M/DD/YY")
            relationships {
              field_media_file {
                filename
                filemime
                uri {
                  url
                }
              }
              uid {
                name
                field_last_name
                field_first_name
              }
              field_reference {
                field_document_type_code
              }
              field_department {
                name
              }
            }
          }
        }
      }
    `
  );

  // Clean up the field mapping so it's easier to reference them
  // in the component.
  const mobileDocuments = allMediaDocument.nodes.map((item) => {
    const fileName = item?.relationships?.field_media_file?.filemime;
    const fileType = fileName.replace('application/', '').toUpperCase();
    const status = `${item?.field_status
      .charAt(0)
      .toUpperCase()}${item?.field_status.slice(1)}`;
    return {
      name: (
        <>
          <div>
            <Button
              className={styles.mobileDocumentName}
              aria-label="Read more on this document"
              to={`/document/${item?.relationships?.field_media_file?.filename}`}
              color="transparent"
            >
              {item?.name}
            </Button>
          </div>
          <div>
            <strong>Reference: </strong>
            {item?.relationships?.field_reference?.field_document_type_code}
            {item?.field_reference?.value}
          </div>
          <div>
            <strong>Type:</strong> {fileType}
          </div>
          <div>
            <strong>Status: </strong>
            {status}
          </div>
          <div>
            <strong>Department: </strong>
            {item?.relationships?.field_department?.name}{' '}
          </div>
          <div>
            <strong>Created: </strong>
            {item?.created}
          </div>
          <div>
            <strong>Download </strong>
            <Button
              aria-label="Download this document"
              to={`/document/${item?.relationships?.field_media_file?.filename}`}
              color="transparent"
            >
              <Download />
            </Button>
          </div>
        </>
      ),
      // eslint-disable-next-line camelcase
      revised: item?.revision_created,
      relationships: item?.relationships
    };
  });

  return mobileDocuments;
};
