/* eslint-disable camelcase */
import { useStaticQuery, graphql } from 'gatsby';

export const useDocumentFilters = () => {
  const {
    allTaxonomyTermDepartment,
    allUserUser,
    allMediaDocument
  } = useStaticQuery(
    graphql`
      query DocumentFilters {
        allTaxonomyTermDepartment(sort: { fields: [weight], order: ASC }) {
          nodes {
            name
            drupal_id
          }
        }
        allUserUser {
          nodes {
            field_first_name
            field_last_name
            name
            drupal_id
          }
        }
        allMediaDocument {
          edges {
            node {
              name
              field_status
              relationships {
                field_department {
                  name
                  drupal_id
                }
                field_media_file {
                  filename
                  filemime
                  drupal_id
                }
                uid {
                  field_first_name
                  field_last_name
                  drupal_id
                }
              }
              drupal_id
            }
          }
        }
      }
    `
  );

  const department = allMediaDocument?.edges.map((item) => {
    return {
      name: item?.node?.relationships?.field_department.name,
      drupal_id: item?.node?.relationships?.field_department.drupal_id,
      sort_item: item?.node?.relationships?.field_department.drupal_id
    };
  });

  //Check for repeats of the same result in department returns.
  // This should work because the drupal_id will be the same for each dept
  // as it is a tax term.
  let stringDept = department.map(JSON.stringify);
  let cleanStringDept = new Set(stringDept);
  let cleanDepartment = Array.from(cleanStringDept, JSON.parse);

  const authors = allMediaDocument?.edges.map((item) => {
    return {
      name: `${item?.node?.relationships?.uid?.field_first_name} ${item?.node?.relationships?.uid?.field_last_name}`,
      drupal_id: item?.node?.relationships?.uid?.drupal_id,
      sort_item: item?.node?.relationships?.uid?.drupal_id
    };
  });

  const authorsClean = authors.filter((author) => {
    if (author.name === 'null null') {
      return '';
    }
    return author;
  });

  // console.log(authorsClean);

  //Check for repeats of the same result in author returns.
  // This should work because the drupal_id will be the same for each author
  // as it is a tax term.
  let stringAuthor = authorsClean.map(JSON.stringify);
  let cleanStringAuthor = new Set(stringAuthor);
  let cleanAuthor = Array.from(cleanStringAuthor, JSON.parse);

  // console.log(cleanAuthor);

  // Filters returned data by file MIME type so only one appears in filter.
  // @TODO: Re-implement this using the Set method since it's cleaner.
  const uniqueFilesByType = allMediaDocument?.edges.filter(
    (file, index, arr) => index === arr.findIndex((fyl) => (
      fyl.node?.relationships?.field_media_file.filemime ===
          file.node?.relationships?.field_media_file.filemime
      )
  ));

  const files = uniqueFilesByType.map((file) => {
    return {
      name: file.node?.relationships?.field_media_file.filemime,
      drupal_id: file.node?.relationships?.field_media_file.drupal_id,
      sort_item: file.node?.relationships?.field_media_file.filemime
        .replace('application/', '')
        .toUpperCase()
    };
  });

  const statuses = allMediaDocument?.edges.map((status) => {
    const statusClean = `${status.node?.field_status.toUpperCase()}`;
    return {
      name: statusClean,
      // drupal_id: status.node?.drupal_id,
      // sort_item: status.node?.drupal_id
      drupal_id: statusClean,
      sort_item: statusClean, 
    };
  });

  const deDupedStatuses = Array.from(new Set(statuses.map((status) => status.name))).map((name) => {
    return statuses.find((node) => node.name === name);
  });

  return [
    {
      title: 'Department',
      field: 'field_department',
      data: cleanDepartment
    },
    {
      title: 'Type',
      field: 'field_media_file',
      data: files
    },
    {
      title: 'Status',
      field: 'field_status',
      data: deDupedStatuses
    },
    {
      title: 'By',
      field: 'uid',
      data: cleanAuthor
    }
  ];
};

//   return [
//     {
//       title: 'Department',
//       field: 'field_department',
//       data: department
//     },
//     {
//       title: 'Type',
//       field: 'field_media_file',
//       data: files
//     },
//     {
//       title: 'Status',
//       field: 'field_status',
//       data: statuses
//     },
//     {
//       title: 'By',
//       field: 'uid',
//       data: authorsClean
//     }
//   ];
// };
