import React, { useState, useEffect } from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import TwoColLayout from '../components/two-col-layout/two-col-layout';
import DocumentTable from '../components/document-table';
import MobileDocumentTable from '../components/document-table/mobile-document-table';
import FilterContentList from '../components/filter-content-list/filter-content-list';
import { useDocumentsData } from '../hooks/use-documents-data';
import { useDocumentFilters } from '../hooks/use-document-filters';
import { useColumns } from '../components/document-table/makeData';
import { useMobileDocumentsData } from '../hooks/use-mobile-documents-data';
import { useMobileColumns } from '../components/document-table/mobileData';

const DocumentsListing = () => {
  const [isDesktop, setDesktop] = useState(false);
  const handleResize = () => {
    if (window.innerWidth > 576) {
      setDesktop(true);
    } else {
      setDesktop(false);
    }
  };

  // Displays the DocumentTable vs. the MobileDocumentTable depending on viewport width.
  useEffect(() => {
    setDesktop(window.innerWidth > 796);
    window.addEventListener('resize', handleResize);

    // Cleanup the function after the component is unmounted.
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const originalDocuments = useDocumentsData();
  const [documentsState, updateDocuments] = useState(originalDocuments);
  const filters = useDocumentFilters();
  const columns = useColumns();
  // We are passing a different data hook to our mobile table,
  // so we need to include that data here for the filters to work on mobile.
  const originalMobileDocuments = useMobileDocumentsData();
  const [mobileDocumentsState, updateMobileDocuments] = useState(
    originalMobileDocuments
  );
  const mobileColumns = useMobileColumns();

  return (
    <Layout>
      <SEO title="Inside Sandy Springs Documents" />
      <TwoColLayout title="Sandy Springs Document Library">
        <FilterContentList
          data={isDesktop ? originalDocuments : originalMobileDocuments}
          listings={documentsState}
          filters={filters}
          bubbleUpState={isDesktop ? updateDocuments : updateMobileDocuments}
          style={{ marginBottom: '95px' }}
          desc={'Filter Events'}
          color="light"
          type="documents"
        >
          {isDesktop ? (
            <DocumentTable data={documentsState} columns={columns} />
          ) : (
            <MobileDocumentTable
              data={mobileDocumentsState}
              columns={mobileColumns}
            />
          )}
        </FilterContentList>
      </TwoColLayout>
    </Layout>
  );
};

export default DocumentsListing;
