import React from 'react';
import styles from './two-col-layout.module.scss';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Card from '../card';
import CardList from '../card-list';
import Link from '../link';
import { useNewsData } from '../../hooks/use-news-data';
import { useDocumentsData } from '../../hooks/use-documents-data';

const TwoColLayout = ({ title, children }) => {
  const news = useNewsData();
  const documents = useDocumentsData();
  const newsImgSizes =
    '(max-width: 576px) 98vw, (max-width: 795px) 90vw, 344px';
  return (
    <div className={styles.listingLayoutContainer}>
      <div className={styles.leftContentContainer}>
        <div className={styles.leftHeaders}>
          <h1 className={styles.twoColTitle}>{title}</h1>
          <Link className={styles.libraryLink} to="/using-document-library">
            Using the Library
          </Link>
        </div>

        <div>{children}</div>
      </div>
      <div className={styles.rightContentContainer}>
        <Card
          className={styles.rightContainerItem}
          title={news[0].title}
          image={news[0].largeImage}
          srcset={news[0]?.srcSet?.srcs?.ratio16x9}
          sizes={newsImgSizes}
          tag={news[0].tag}
          date={news[0].dateShort}
          summary={news[0].summary}
          url={news[0].url}
          type={news[0].type}
          cardSummaryOnly={news[0].cardSummaryOnly}
        />
        <Card
          className={styles.rightContainerItem}
          title={news[1].title}
          image={news[1].largeImage}
          srcset={news[1]?.srcSet?.srcs?.ratio16x9}
          sizes={newsImgSizes}
          tag={news[1].tag}
          date={news[1].dateShort}
          summary={news[1].summary}
          url={news[1].url}
          type={news[1].type}
          cardSummaryOnly={news[1].cardSummaryOnly}
        />
        <CardList
          className={classnames(styles.rightContainerItem, styles.newsListCard)}
          cards={news}
          heading="Latest News"
          moreText="More News"
          moreLink="/page"
        />
        <CardList
          className={styles.documentsCard}
          cards={documents}
          heading="Recent Documents"
          moreText="View More"
          moreLink="/documents"
        />
      </div>
    </div>
  );
};

TwoColLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default TwoColLayout;
