import React from 'react';
import { useTable, useSortBy, usePagination } from 'react-table';

import { ReactComponent as ArrowDown } from './assets/arrow-down.svg';
import { ReactComponent as ArrowUp } from './assets/arrow-up.svg';
import styles from './document-table.module.scss';

const MobileDocumentTable = ({ data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page.
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex }
  } = useTable(
    { columns, data, initialState: { pageIndex: 0, pageSize: 10 } },
    useSortBy,
    usePagination
  );

  return (
    <>
      <table className={styles.documentTable} {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={styles.tableHeaders}
                  key={index}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <span
                    className={
                      column.isSorted ? styles.activeHeader : undefined
                    }
                  >
                    {column.render('Header')}
                    {/* Add a sort direction indicator */}
                    <span className={styles.columnArrows}>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <ArrowUp />
                        ) : (
                          <ArrowDown />
                        )
                      ) : (
                        ''
                      )}
                    </span>
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      key={index}
                      {...cell.getCellProps()}
                      className={styles.tableRow}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={styles.pagination}>
        <span className={styles.paginationLabels}>
          Jump to page{' '}
          <input
            type="text"
            className={styles.pageNumberInput}
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
          />
        </span>{' '}
        <div className={styles.paginationCenter}>
          <button
            className={styles.paginationButton}
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
          >
            {'<'}
          </button>{' '}
          <span className={styles.pageCount}>
            {pageIndex + 1} of {pageOptions.length}
          </span>
          <button
            className={styles.paginationButton}
            onClick={() => nextPage()}
            disabled={!canNextPage}
          >
            {'>'}
          </button>{' '}
        </div>
      </div>
    </>
  );
};

export default MobileDocumentTable;
